<template>
  <div class="recruit">
    <TopNavBar2 />
    <Carousel :carousels="carousels" />
    <div class="box">
      <div class="welfare_box">
        <Title title="福利待遇" />
        <div class="welfares">
          <div v-for="(item, index) in welfares" :key="index" class="item">
            <div>
              <img :src="item.imgUrl" alt="" />
              <span>{{ item.name }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="position_box">
      <Title title="招聘职位" />
      <div class="positions">
        <div class="item" v-for="(item, index) in positions" :key="index">
          <h3>{{ item.name }}</h3>
          <p>{{ item.price }}</p>
          <span>工作经验：{{ item.experience }}</span>
          <span>学历要求：{{ item.education }}</span>
          <span>招聘人数：{{ item.number }}</span>
          <div>
            <el-button type="text" @click="toDetails(item)">去应聘 ></el-button>
          </div>
        </div>
      </div>
    </div>
    <div class="box">
      <div class="environment_box">
        <Title title="办公环境" />
        <div class="environments">
          <img
            @click="num >= 1 ? onMove('left') : null"
            :src="
              require('@/assets/images/recruit/environment/left' +
                (num >= 1 ? '' : '_dis') +
                '.png')
            "
            alt=""
          />
          <div class="img_box">
            <img
              :src="
                require('@/assets/images/recruit/environment/img' +
                  (index + num) +
                  '.svg')
              "
              alt=""
              v-for="(item, index) in 3"
              :key="index"
            />
          </div>
          <img
            @click="num <= 1 ? onMove('right') : null"
            :src="
              require('@/assets/images/recruit/environment/right' +
                (num <= 1 ? '' : '_dis') +
                '.png')
            "
            alt=""
          />
        </div>
      </div>
    </div>
    <Footer2 />
  </div>
</template>

<script>
import { welfares, positions } from "@/assets/js/staticMobile.js";
export default {
  name: "Recruit",
  data() {
    return {
      welfares,
      positions,
      num: 0,
      carousels: [
        {
          imgUrl: require("@/assets/images/carousels/6.png"),
        },
      ],
    };
  },
  mounted() {},
  methods: {
    onMove(value) {
      switch (value) {
        case "left":
          this.num--;
          break;
        case "right":
          this.num++;
          break;
      }
    },
    toDetails(item) {
      console.log(item);
      this.$router.push("/mobile/recruitDetails");
    },
  },
};
</script>

<style scoped lang="scss">
.recruit {
  .box {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .welfare_box {
    margin-top: 20px;
    .welfares {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-content: center;
      .item {
        width: 33%;
        display: flex;
        align-items: center;
        margin-top: 10px;
        > div {
          padding: 0 10px;
          display: flex;
          align-items: center;
          > img {
            width: 20px;
            height: 20px;
          }
          > span {
            font-family: "AlibabaPuHuiTi-Light";
            font-weight: 200;
            font-size: 15px;
            letter-spacing: 1.7px;
            color: #333333;
            padding-left: 5px;
            line-height: 40px;
          }
        }
      }
    }
  }
  .position_box {
    margin-top: 40px;
    padding: 0 12px;
    .positions {
      margin-top: 10px;
      display: flex;
      justify-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
      .item {
        padding: 20px 0 25px;
        width: 165px;
        height: 215px;
        box-shadow: 0px 0px 5px #86baea40;
        margin-top: 15px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        h3 {
          font-family: "AlibabaPuHuiTi-Regular";
          font-weight: 400;
          font-size: 15px;
          color: #000000;
        }
        p {
          line-height: 40px;
          font-family: "AlibabaPuHuiTi-Regular";
          font-weight: 400;
          font-size: 15px;
          color: #fb8742;
        }
        span {
          padding-left: 30px;
          font-family: "AlibabaPuHuiTi-Light";
          font-size: 14px;
          color: #333;
          text-align: left;
          font-weight: 200;
          color: #333333;
          line-height: 40px;
        }
        > div {
          padding: 0 25px;
          text-align: right;
          .el-button {
            font-size: 14px;
            padding: 0;
          }
        }
      }
    }
  }
  .environment_box {
    width: 345px;
    margin: 40px 0 30px;
    .environments {
      margin-top: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      > img {
        width: 15px;
        height: 20px;
        cursor: pointer;
      }
      .img_box {
        width: 100%;
        padding: 0 10px;
        height: 60px;
        display: flex;
        justify-content: space-between;
        img {
          width: 95px;
          height: 60px;
        }
      }
    }
  }
}
</style>
