var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"recruit"},[_c('TopNavBar2'),_c('Carousel',{attrs:{"carousels":_vm.carousels}}),_c('div',{staticClass:"box"},[_c('div',{staticClass:"welfare_box"},[_c('Title',{attrs:{"title":"福利待遇"}}),_c('div',{staticClass:"welfares"},_vm._l((_vm.welfares),function(item,index){return _c('div',{key:index,staticClass:"item"},[_c('div',[_c('img',{attrs:{"src":item.imgUrl,"alt":""}}),_c('span',[_vm._v(_vm._s(item.name))])])])}),0)],1)]),_c('div',{staticClass:"position_box"},[_c('Title',{attrs:{"title":"招聘职位"}}),_c('div',{staticClass:"positions"},_vm._l((_vm.positions),function(item,index){return _c('div',{key:index,staticClass:"item"},[_c('h3',[_vm._v(_vm._s(item.name))]),_c('p',[_vm._v(_vm._s(item.price))]),_c('span',[_vm._v("工作经验："+_vm._s(item.experience))]),_c('span',[_vm._v("学历要求："+_vm._s(item.education))]),_c('span',[_vm._v("招聘人数："+_vm._s(item.number))]),_c('div',[_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.toDetails(item)}}},[_vm._v("去应聘 >")])],1)])}),0)],1),_c('div',{staticClass:"box"},[_c('div',{staticClass:"environment_box"},[_c('Title',{attrs:{"title":"办公环境"}}),_c('div',{staticClass:"environments"},[_c('img',{attrs:{"src":require('@/assets/images/recruit/environment/left' +
              (_vm.num >= 1 ? '' : '_dis') +
              '.png'),"alt":""},on:{"click":function($event){_vm.num >= 1 ? _vm.onMove('left') : null}}}),_c('div',{staticClass:"img_box"},_vm._l((3),function(item,index){return _c('img',{key:index,attrs:{"src":require('@/assets/images/recruit/environment/img' +
                (index + _vm.num) +
                '.svg'),"alt":""}})}),0),_c('img',{attrs:{"src":require('@/assets/images/recruit/environment/right' +
              (_vm.num <= 1 ? '' : '_dis') +
              '.png'),"alt":""},on:{"click":function($event){_vm.num <= 1 ? _vm.onMove('right') : null}}})])],1)]),_c('Footer2')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }